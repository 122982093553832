import React, { Component } from 'react';
import Wrapper from "./components/Wrapper";

class App extends Component {
    render() {
        return (
            <Wrapper/>
        );
    }
}

export default App;
