export const workingPlace = [
    { title: 'biuro', name: 'office' },
    { title: 'domek', name: 'home' },
]

export const parking = [
    { title: 'duży garaż', name: 'big' },
    { title: 'mały garaż', name: 'small' },
    { title: 'przed dużym garażem', name: 'inFrontOfBig' },
    { title: 'przed małym garażem', name: 'inFrontOfSmall' },
]

export const superPowers = [
    { title: 'mam klucz', name: 'key' }
]