import styled from 'styled-components'

export default styled.div`
  text-align: center;
  
  h1 {
    font-size: 2em;
    margin: 1em 0;
  }
`
